import { useDashboardStore } from '@/store/dashboard.js'

export const fields = {
  cloud_provider: {
    label: 'Cloud provider',
    icon: 'cloud-circle-outline',
    operators: ['is in', 'is not in'],
    defaultValue: []
  },
  cloud_account: {
    label: 'Cloud account',
    icon: 'cloud-key-outline',
    operators: ['is in', 'is not in'],
    defaultValue: []
  },
  cloud_region: {
    label: 'Cloud region',
    icon: 'earth',
    operators: ['is in', 'is not in'],
    defaultValue: []
  },
  cloud_service: {
    label: 'Cloud service',
    icon: 'cloud-cog-outline',
    operators: ['is in', 'is not in'],
    defaultValue: []
  },
  sep1: 'separator',
  resource_name: {
    label: 'Resource name',
    icon: 'package-variant',
    operators: ['is in', 'is not in', 'contains', 'does not contain'],
    defaultValue: []
  },
  cost: {
    label: 'Cost',
    icon: 'currency-usd',
    operators: ['is more than', 'is less than', 'is equal to', 'is between'],
    defaultValue: []
  },
  /*creation_date: {
    label: 'Creation date',
    icon: 'calendar',
    operators: ['is', 'is before', 'is after', 'is between'],
    defaultValue: []
  },*/
  sep2: 'separator',
  tags: {
    label: 'Tags',
    icon: 'tag-multiple-outline',
    operators: ['is', 'is not', 'contains', 'does not contain'],
    defaultValue: ''
  },
  virtual_tags: {
    label: 'Virtual Tags',
    icon: 'tag-multiple-outline',
    operators: ['is', 'is not', 'contains', 'does not contain'],
    defaultValue: ''
  }
}

const excludeFilters = {
  cloud_service: ['aws']
}

export function filter(data, source = null, costField = 'cost') {
  const dashboardStore = useDashboardStore()
  const filters = source || dashboardStore.getFilters

  return data.filter((row) => {
    let keep = true
    if ('cloud_provider' in filters) {
      const { operator, value } = filters.cloud_provider
      if (row.account) {
        const provider = row.account.provider_name
        const isProvider = value.includes(provider)
        if (operator === 'is in') {
          keep = keep && isProvider
        } else {
          keep = keep && !isProvider
        }
      }
    }
    if ('cloud_account' in filters) {
      const { operator, value } = filters.cloud_account
      if (row.account) {
        const accountID = row.account.id
        const isAccount = value.includes(accountID)
        if (operator === 'is in') {
          keep = keep && isAccount
        } else {
          keep = keep && !isAccount
        }
      }
    }
    if ('cloud_service' in filters && !excludeFilters.cloud_service?.includes(row.provider)) {
      const { operator, value } = filters.cloud_service
      if (row.service) {
        const services = value.map((v) => v.toLowerCase())
        const service = row.service.toLowerCase()
        const some = services.some((v) => service.endsWith(v))
        if (operator === 'is in') {
          keep = keep && some
        } else if (operator === 'is not in') {
          keep = keep && !some
        }
      }
    }
    if ('cloud_region' in filters && !excludeFilters.cloud_region?.includes(row.provider)) {
      const { operator, value } = filters.cloud_region
      if (row.region) {
        if (Array.isArray(row.region)) {
          const regions = value.map((v) => v.toLowerCase())
          const some = regions.some((v) => row.region.includes(v))
          if (operator === 'is in') {
            keep = keep && some
          } else {
            keep = keep && !some
          }
        } else {
          const region = row.region.toLowerCase()
          if (region !== 'loading') {
            const regions = value.map((v) => v.toLowerCase())
            const some = regions.some((v) => region.endsWith(v))
            if (operator === 'is in') {
              keep = keep && some
            } else {
              keep = keep && !some
            }
          }
        }
      }
    }
    if ('cost' in filters && !excludeFilters.cost?.includes(row.provider)) {
      const { operator, value } = filters.cost
      const min = parseFloat(value[0])
      const max = parseFloat(value[1])
      const cost = row[costField]
      if (cost !== undefined) {
        if (operator === 'is more than') {
          keep = keep && cost > min
        } else if (operator === 'is less than') {
          keep = keep && cost < min
        } else if (operator === 'is equal to') {
          keep = keep && cost === min
        } else if (operator === 'is between') {
          keep = keep && cost > min && cost < max
        }
      }
    }
    const tagsFilters = Object.keys(filters).filter((key) => key.startsWith('tags'))
    if (tagsFilters.length && !excludeFilters.tags?.includes(row.provider)) {
      for (const filter of tagsFilters) {
        const { key, operator, value } = filters[filter]
        const { tags } = row
        if (Array.isArray(tags)) {
          const keys = tags.map((tag) => tag.key)
          // `query` is "tag key" if no `key` is present. Otherwise, it is "tag value".
          const query = value.toLowerCase()

          // No key specified, try to match in "tag key".
          if (!key) {
            const exactMatchInKeys = keys.find((key) => key.toLowerCase() === query) !== undefined
            const includeInKeys = keys.find((key) => key.toLowerCase().includes(query)) !== undefined
            if (operator === 'is') {
              keep = keep && exactMatchInKeys
            } else if (operator === 'is not') {
              keep = keep && !exactMatchInKeys
            } else if (operator === 'contains') {
              keep = keep && includeInKeys
            } else if (operator === 'does not contain') {
              keep = keep && !includeInKeys
            }
          } else {
            // Key is defined, now let's try to look for tags.
            const foundTag = tags.find((tag) => tag.key.toLowerCase() === key.toLowerCase())
            if (foundTag) {
              const foundTagValue = foundTag.value.toLowerCase()
              if (operator === 'is') {
                keep = keep && foundTagValue === query
              } else if (operator === 'is not') {
                keep = keep && foundTagValue !== query
              } else if (operator === 'contains') {
                keep = keep && foundTagValue.includes(query)
              } else if (operator === 'does not contain') {
                keep = keep && !foundTagValue.includes(query)
              }
            } else {
              keep = false
            }
          }
        } else {
          keep = false
        }
      }
    }

    const virtualTagsFilters = Object.keys(filters).filter((key) => key.startsWith('virtual_tags'))
    if (virtualTagsFilters.length && !excludeFilters.virtual_tags?.includes(row.provider)) {
      for (const filter of virtualTagsFilters) {
        const { key, operator, value } = filters[filter]
        const { virtualTags } = row

        if (Array.isArray(virtualTags)) {
          const keys = virtualTags.map((tag) => tag.key)
          const query = value.toLowerCase()

          if (!key) {
            const exactMatchInKeys = keys.find((key) => key.toLowerCase() === query) !== undefined
            const includeInKeys = keys.find((key) => key.toLowerCase().includes(query)) !== undefined
            if (operator === 'is') {
              keep = keep && exactMatchInKeys
            } else if (operator === 'is not') {
              keep = keep && !exactMatchInKeys
            } else if (operator === 'contains') {
              keep = keep && includeInKeys
            } else if (operator === 'does not contain') {
              keep = keep && !includeInKeys
            }
          } else {
            // Key is defined, now let's try to look for tags.
            const foundTag = virtualTags.find((tag) => tag.key.toLowerCase() === key.toLowerCase())
            if (foundTag) {
              const foundTagValue = foundTag.value.toLowerCase()
              if (operator === 'is') {
                keep = keep && foundTagValue === query
              } else if (operator === 'is not') {
                keep = keep && foundTagValue !== query
              } else if (operator === 'contains') {
                keep = keep && foundTagValue.includes(query)
              } else if (operator === 'does not contain') {
                keep = keep && !foundTagValue.includes(query)
              }
            } else {
              keep = false
            }
          }
        } else {
          keep = false
        }
      }
    }

    return keep
  })
}
