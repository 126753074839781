import { format } from '@joint/plus'
import { Base64 } from 'js-base64'
import { useDashboardStore } from '@/store/dashboard.js'
import { useAppStore } from '@/store/app.js'
import { useMixins } from '@/common/mixins.js'

export const debounce = (fn, delay = 300) => {
  let timer
  return function () {
    const context = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}

export const lowercaseKeys = (obj) => {
  const entries = Object.entries(obj)

  return Object.fromEntries(
    entries.map(([key, value]) => {
      return [key.toLowerCase(), typeof value === 'object' ? lowercaseKeys(value) : value]
    })
  )
}

export const priceFormatterOptions = {
  style: 'currency',
  maximumSignificantDigits: 8,
  maximumFractionDigits: 4
}

export function formatPrice(price, currency = 'USD') {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currencyDisplay: 'narrowSymbol',
    maximumSignificantDigits: 4,
    maximumFractionDigits: 4,
    currency
  })
  return formatter.format(price)
}

const ticks = {
  color: '#6F6F77',
  font: {
    family: 'Inter, sans-serif',
    size: 12
  }
}

export const chartjsOptions = {
  scales: {
    x: {
      ticks
    },
    y: {
      ticks
    }
  },
  plugins: {
    datalabels: {
      display: false
    },
    legend: {
      labels: {
        color: '#6F6F77',
        font: {
          family: 'Inter, sans-serif',
          size: 12
        }
      }
    }
  }
}

export const availableRoles = [
  {
    name: 'owner',
    description: 'Owners have full access to billing, views and team management.',
    permissions: ['edit-billing', 'edit-views', 'edit-teams', 'edit-cloud-accounts']
  },
  {
    name: 'editor',
    description: 'Editors have full access to billing and views but can’t manage teams.',
    permissions: ['edit-billing', 'edit-views']
  },
  {
    name: 'viewer',
    description:
      'Viewers can see billing, recommendations but won’t be able to modify elements or create views.',
    permissions: []
  }
]

export function downloadFile(content, fileName, format) {
  const link = document.createElement('a')
  link.setAttribute('download', fileName)

  if (format === 'image/svg') {
    link.setAttribute('href', `data:image/svg+xml;base64,${btoa(content)}`)
  } else {
    link.setAttribute('href', content)
  }

  link.click()
}

export function exportToRaster(svg, fileFormat, finish, addWhiteBackground = false, customDimensions = null) {
  let width, height
  if (!customDimensions) {
    const area = window.paper.getArea()
    width = area.width
    height = area.height
  } else {
    width = customDimensions.width
    height = customDimensions.height
  }
  const img = new Image();
  img.width = width
  img.height = height
  img.onload = () => {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const ctx = canvas.getContext('2d')

    if (addWhiteBackground) {
      ctx.fillStyle = '#ffffff'
      ctx.fillRect(0, 0, width, height)
    }

    ctx.drawImage(img, 0, 0, width, height)

    const canvasData = canvas.toDataURL(fileFormat)
    finish(canvasData)
  }
  img.setAttribute('crossOrigin', 'Anonymous')
  img.src = `data:image/svg+xml;base64,${Base64.encode(svg)}`
}

export const exportDiagramImage = debounce((
  whiteBackground,
  fileFormat,
  filenameOrCallback,
  cb,
  displayToast = true
) => {
  const { toast, closeAllToasts } = useMixins()

  if (displayToast) {
    toast({
      variant: 'info',
      infinite: true,
      message: 'Your image is being generated.<br>Once it is ready, you will be prompted to download it.',
      position: 'top-right'
    })
  }

  const finish = (blob) => {
    if (displayToast) {
      closeAllToasts()
    }

    if (typeof filenameOrCallback === 'string') {
      downloadFile(blob, filenameOrCallback, fileFormat)
    } else {
      filenameOrCallback(blob)
    }

    if (typeof cb === 'function') {
      cb()
    }
  }

  format.toSVG(
    window.paper,
    (svg) => {
      if (fileFormat !== 'image/svg') {
        exportToRaster(svg, fileFormat, finish, whiteBackground)
      } else {
        finish(svg)
      }
    },
    {
      backgroundColor: whiteBackground ? '#ffffff' : 'transparent',
      useComputedStyles: false,
      convertImagesToDataUris: true,
      padding: 30
    }
  )
}, 100)

/**
 * Format a tag to use `key` and `value`, from different formats of providers.
 * @param {Object} tag
 * @returns {{ key: string, value: any, date: string}}
 */
export const formatTag = (tag) => ({
  key: tag.key || tag.Key,
  value: tag.value || tag.Value,
  date: tag.date || tag.LastReportedAt
})

export const providerColors = {
  'Alibaba Cloud': '#FE6900',
  alibaba: '#FE6900',
  'Amazon Web Services': '#FF9900',
  aws: '#FF9900',
  'Microsoft Azure': '#36B9ED',
  azure: '#36B9ED',
  'Google Cloud Platform': '#FF3D00',
  gcp: '#FF3D00',
  Linode: '#1CB35C',
  linode: '#1CB35C',
  OVH: '#000E9C',
  ovh: '#000E9C',
  Oracle: '#F80102',
  oracle: '#F80102',
  oci: '#F80102',
  'Digital Ocean': '#0080FF',
  'digital ocean': '#0080FF',
  Scaleway: '#4F0699',
  scaleway: '#4F0699'
}

/**
 * Extracts the fulfilled values from Promise.allSettled.
 * @param {PromiseSettledResult<Awaited<any>>} results The array of promises results.
 * @returns {{allData: Array<Object>, allErrors: Array<String>}}
 */
export function extractFulfilledValues(results) {
  const allData = results.filter(({ status }) => status === 'fulfilled').map(({ value }) => value)
  const allErrors = results
    .filter(({ status, reason }) => status === 'rejected' && reason !== 'canceled')
    .map(({ reason }) => reason)
  return { allData, allErrors }
}

export function generateThumbnail(paper = window.paper) {
  return new Promise((resolve) => {
    format.toJPEG(
      paper,
      (dataURI) => {
        resolve(dataURI)
      },
      {
        width: 200,
        height: 130,
        quality: 0.8,
        padding: 10,
        useComputedStyles: false
      }
    )
  })
}

export const na = 'N/A'

export function getAWSNameFromTags(resourceID, resourceTags = null) {
  const dashboardStore = useDashboardStore()

  const tags = resourceTags || dashboardStore.resourcesData[resourceID]?.tags || []

  if (tags === 'loading') {
    return resourceID
  }

  if (Array.isArray(tags)) {
    const nameTag = tags.find(({ key }) => key.toLowerCase() === 'name' || key.toLowerCase() === 'user_name')
    if (nameTag) {
      return nameTag.value || resourceID
    }
  }

  return resourceID
}

export function addNetworkError(error) {
  const appStore = useAppStore()

  appStore.addNetworkError(error)
}

export const USER_LOCALE = (new Intl.NumberFormat()).resolvedOptions().locale.substring(0, 2)

export function logoutWithRedirect() {
  const split = window.location.pathname.split('/')
  split.shift()
  const url = split.join('/')

  if (window.location.pathname !== '/login') {
    localStorage.setItem('inactivityLogout', true)
    window.location = '/login?redirect=' + url
  }
}

// Temporary.
window.showNetworkErrors = function() {
  const appStore = useAppStore()
  console.log(appStore.networkErrors)
}

window.cleanCache = function() {
  const dashboardStore = useDashboardStore()
  dashboardStore.clearCache()
}