import { ref } from 'vue'
import { useAppStore } from '@/store/app.js'
import { useDashboardStore } from '@/store/dashboard.js'
import { v4 } from 'uuid'
import moment from 'moment'
import { filter } from '@/common/dashboard/filters.js'
import { appendVirtualTags, appendVirtualTagsOnResource } from '@/common/cost-dashboard/virtual-tags.js'
import {
  getAWSColumnsIndexes,
  getResourceID,
  getResourceName
} from '@/common/cost-dashboard/utils.js'
import { debounce } from '@/common/utils.js'

export const UNKNOWN_RESOURCE_ID = 'other'

function handleAWSResource(resourceID, cost, currency, providedService, providedRegion, providedTags, providerData, resources, tagsSource, subaccount) {
  const appStore = useAppStore()

  let displayResourceID = resourceID
  if (resourceID.startsWith('arn:aws')) {
    const split = resourceID.split(':')
    displayResourceID = split.pop().split('/').pop()
  }

  let service = providedService || 'Not found'
  let region = providedRegion || 'Not found'
  let tags = providedTags || []
  let resourceName = getResourceName(resourceID, 'aws', tags.length ? tags : null)

  const resourceData = tagsSource[resourceID]
  if (resourceData) {
    const { service: dataService, region: dataRegion, tags: dataTags } = resourceData
    const nameTag = Array.isArray(dataTags)
      ? tags.find(({ key }) => key === 'Name')
      : null

    service = providedService || dataService
    region = providedRegion || dataRegion
    tags = providedTags || dataTags || []

    if (nameTag) {
      resourceName = nameTag.value
    }
  }

  if (!(resourceID in resources)) {
    resources[resourceID] = {
      id: v4(),
      provider: providerData.provider,
      resourceName,
      resourceID,
      displayResourceID,
      subaccount,
      cost: 0,
      currency,
      service,
      region,
      tags,
      account: providerData.account,
      providerTags: []
    }
  }

  resources[resourceID].cost += appStore.convertCurrency(cost, currency || 'USD')
}

export function getTableDataGroupedByResource(source = undefined) {
  const appStore = useAppStore()
  const dashboardStore = useDashboardStore()

  const usedSource = source || dashboardStore.allResourcesData.RESOURCE_ID

  const allResources = usedSource.reduce(
    (allData, providerData) => {
      switch (providerData.provider) {
        case 'aws': {
          const resources = {}

          if (!Array.isArray(providerData.data[0])) {
            for (const row of providerData.data) {
              handleAWSResource(row.resourceID || UNKNOWN_RESOURCE_ID, row.cost, row.currency, row.service, row.region, row.tags, providerData, resources, dashboardStore.resourcesData, row.subaccount)
            }
          } else {
            const indexes = getAWSColumnsIndexes(providerData.data)
            for (const row of providerData.data.slice(1)) {
              const resourceID = row[indexes.resourceID]
              const cost = parseFloat(row[indexes.cost])
              const currency = row[indexes.currency]
              handleAWSResource(resourceID || UNKNOWN_RESOURCE_ID, cost, currency, null, null, [], providerData, resources, dashboardStore.resourcesData, '')
            }
          }
          return allData.concat(Object.values(resources))
        }
        case 'azure': {
          const resources = {}
          const resourcesTags = {}
          if (Array.isArray(providerData.data)) {
            providerData.data.forEach((line) => {
              const resourceID = line.resource_id
              const currency = 'USD'
              const cost = appStore.convertCurrency(line.cost, currency)
              const { service, region, account_id } = line

              if (!(resourceID in resources)) {
                resources[resourceID] = {
                  id: v4(),
                  provider: 'azure',
                  resourceName: getResourceName(resourceID, 'azure'),
                  resourceID,
                  displayResourceID: resourceID,
                  cost,
                  currency,
                  service,
                  region,
                  tags: [],
                  account: providerData.account,
                  providerTags: [],
                  subaccount: account_id
                }
                resourcesTags[resourceID] = {}
              }
              resources[resourceID].cost += cost

              for (const tagObj of line.tags) {
                for (const key in tagObj) {
                  resourcesTags[resourceID][key] = tagObj[key]
                }
              }

              resources[resourceID].tags = Object.keys(resourcesTags[resourceID]).map((key) => ({
                key,
                value: resourcesTags[resourceID][key]
              }))
            })
          }
          return allData.concat(Object.values(resources))
        }
        case 'gcp': {
          const resources = {}
          const resourcesTags = {}
          if (Array.isArray(providerData.data)) {
            providerData.data.forEach((resource) => {
              const {
                resource_id,
                resource_name,
                service,
                account_id,
                region,
                tags
              } = resource

              const cost = appStore.convertCurrency(resource.cost, 'USD')

              const resourceID = resource_id?.toLowerCase() || UNKNOWN_RESOURCE_ID

              if (!(resourceID in resources)) {
                resources[resourceID] = {
                  id: v4(),
                  provider: 'gcp',
                  resourceName: getResourceName(resource_name || resourceID, 'gcp'),
                  resourceID: resourceID,
                  displayResourceID: getResourceID(resourceID, 'gcp'),
                  subaccount: account_id,
                  cost,
                  currency: 'USD',
                  service,
                  region,
                  tags,
                  account: providerData.account,
                  providerTags: []
                }
                resourcesTags[resourceID] = {}
              }
              resources[resourceID].cost += cost

              for (const tag of tags) {
                resourcesTags[resourceID][tag.key] = tag.value
              }

              resources[resourceID].tags = Object.keys(resourcesTags[resourceID]).map((key) => ({
                key,
                value: resourcesTags[resourceID][key]
              }))
            })
          }
          return allData.concat(Object.values(resources))
        }
        case 'oci': {
          const resources = {}
          const resourcesTags = {}
          providerData.data.forEach((resource) => {
            const { resource_id: resourceID, resource_name, service, currency, region, tags } = resource

            const cost = appStore.convertCurrency(parseFloat(resource.attributed_cost), currency.trim())

            if (!(resourceID in resources)) {
              resources[resourceID] = {
                id: v4(),
                provider: 'oci',
                resourceName: resource_name || resourceID,
                resourceID: resourceID,
                displayResourceID: resource_name || resourceID,
                cost,
                currency,
                service,
                region,
                account: providerData.account,
                providerTags: []
              }
              resourcesTags[resourceID] = {}
            }

            if (resources[resourceID].currency?.trim().length === 0 && currency.trim() !== '') {
              resources[resourceID].currency = currency
            }

            resources[resourceID].cost += cost

            for (const tag of tags) {
              if (tag.key) {
                resourcesTags[resourceID][tag.key] = tag.value
              }
            }

            resources[resourceID].tags = Object.keys(resourcesTags[resourceID]).map((key) => ({
              key,
              value: resourcesTags[resourceID][key]
            }))
          })
          return allData.concat(Object.values(resources))
        }
        case 'scaleway': {
          const resources = {}
          const resourcesTags = {}
          providerData.data.forEach((resource) => {
            const { resource_name: resourceID, category_name: service, currency, region } = resource

            const cost = appStore.convertCurrency(parseFloat(resource.total_cost), currency)

            if (!(resourceID in resources)) {
              resources[resourceID] = {
                id: v4(),
                provider: 'scaleway',
                resourceName: resourceID,
                resourceID: resourceID,
                displayResourceID: resourceID,
                cost,
                currency,
                service,
                region,
                account: providerData.account,
                providerTags: [],
                tags: []
              }
              resourcesTags[resourceID] = {}
            }

            resources[resourceID].cost += cost
          })
          return allData.concat(Object.values(resources))
        }
        default:
          return allData
      }
    },
    []
  )

  return appendVirtualTags(allResources)
}

export function getTableDataGroupedByRegion(regionsNumbers) {
  const appStore = useAppStore()
  const dashboardStore = useDashboardStore()

  const regions = {}

  function addToRegions(region, provider, cost, currency, account, increment = 0) {
    if (!(region in regions)) {
      regions[region] = {
        provider,
        account,
        cost: 0,
        currency,
        count: regionsNumbers[provider + '/' + region] || 0,
        resources: new Set()
      }
    }

    if (regions[region].currency.length === 0 && currency.length > 0) {
      regions[region].currency = currency
    }

    regions[region].cost += appStore.convertCurrency(cost, currency)

    if (increment) {
      regions[region].count = increment
    }
  }

  dashboardStore.allResourcesData.REGION.forEach((providerData) => {
    const { provider, data, account } = providerData

    switch (providerData.provider) {
      case 'aws':
      case 'azure':
      case 'gcp':
        for (const row of data) {
          const { region, cost, currency } = row
          addToRegions(
            region,
            provider,
            cost,
            currency,
            account
          )
        }
        break
      case 'oci': {
        for (const row of data) {
          addToRegions(row.region, 'oci', parseFloat(row.attributed_cost), row.currency, providerData.account, 1)
        }
        break
      }
    }
  })

  return Object.keys(regions)
    .map((region) => ({
      id: v4(),
      region,
      ...regions[region]
    }))
    .sort((a, b) => (a.count > b.count ? -1 : 1))
}

export function getTableDataGroupedByService(servicesNumbers) {
  const appStore = useAppStore()
  const dashboardStore = useDashboardStore()

  const services = {}

  function addToServices(service, provider, cost, currency, account, increment = 0) {
    if (!(service in services)) {
      services[service] = {
        provider,
        account,
        cost: 0,
        currency,
        count: servicesNumbers[provider + '/' + service] || 0,
        resources: new Set()
      }
    }

    services[service].cost += appStore.convertCurrency(cost, currency)

    if (increment) {
      services[service].count = increment
    }
  }

  dashboardStore.allResourcesData.SERVICE.forEach((providerData) => {
    const { provider, data, account } = providerData

    switch (provider) {
      case 'aws':
      case 'azure':
      case 'gcp':
        for (const row of data) {
          const { service, cost, currency } = row
          addToServices(service, provider, cost, currency, account)
        }
        break
      case 'oci':
        for (const row of data) {
          const { service, attributed_cost, currency } = row
          addToServices(service, provider, parseFloat(attributed_cost), currency.trim(), account, 1)
        }
        break
    }
  })

  return Object.keys(services)
    .map((service) => ({
      id: v4(),
      service,
      ...services[service]
    }))
    .sort((a, b) => (a.count > b.count ? -1 : 1))
}

export function getTableDataGroupedByTagKey(virtual) {
  const keys = {}
  const resources = getTableDataGroupedByResource()
  const allVirtualTags = resources.map(({ virtualTags }) => virtualTags).flat()
  const allTags = resources.map(({ tags }) => tags).flat()

  if (virtual) {
    for (const virtualTag of allVirtualTags) {
      const { key, value } = virtualTag
      if (!(key in keys)) {
        keys[key] = {
          values: new Set(),
          resources: 0,
          type: 'virtual'
        }
      }

      keys[key].values.add(value)
      keys[key].resources++
    }
  } else {
    for (const tag of allTags) {
      const { key, value } = tag

      if (!key) {
        continue
      }

      if (!(key in keys)) {
        keys[key] = {
          values: new Set(),
          resources: 0,
          type: 'provider',
          cost: 0
        }
      }

      keys[key].values.add(value)
      keys[key].resources++
    }
  }

  for (const key in keys) {
    const resourcesWithThisTag = resources.filter(({ tags }) => Array.isArray(tags) ? tags.find((thisTag) => thisTag.key === key) : false)
    keys[key].cost = resourcesWithThisTag.reduce((acc, curr) => acc + curr.cost, 0)
  }

  return Object.keys(keys)
    .map((key) => ({
      id: v4(),
      key,
      ...keys[key],
      values: Array.from(keys[key].values).filter((val) => val !== '')
    }))
    .sort((a, b) => (a.resources > b.resources ? -1 : 1))
}

export function getTableDataGroupedByTagValue(virtual) {
  const byTagKey = getTableDataGroupedByTagKey(virtual)
  const resources = getTableDataGroupedByResource()
  const allValues = Array.from(
    new Set(byTagKey.map(({ values }) => values).flat())
  ).sort()

  const values = {}

  for (const value of allValues) {
    const withThisValue = resources.filter(
      ({ tags, virtualTags }) => {
        if (virtual) {
          return Array.isArray(virtualTags) ? virtualTags.some((thisTag) => thisTag.value === value) : false
        } else {
          return Array.isArray(tags) ? tags.some((thisTag) => thisTag.value === value) : false
        }
      })

    values[value] = {
      resources: withThisValue.length,
      cost: withThisValue.reduce((acc, curr) => acc + curr.cost, 0)
    }
  }

  return Object.keys(values).map((value) => ({
    value,
    ...values[value]
  }))
}

const useInventoryResources = (groupBy, servicesRef, regionsRef) => {
  const getInventoryResources = (forceGroupBy) => {
    let reduced
    switch (forceGroupBy) {
      case 'REGION':
        reduced = getTableDataGroupedByRegion(regionsRef.value)
        break
      case 'RESOURCE_ID':
        reduced = getTableDataGroupedByResource()
        break
      case 'SERVICE':
        reduced = getTableDataGroupedByService(servicesRef.value)
        break
      case 'TAGS':
        reduced = getTableDataGroupedByTagKey(false)
        break
      case 'TAGS_VALUES':
        reduced = getTableDataGroupedByTagValue(false)
        break
      case 'VIRTUAL_TAGS':
        reduced = getTableDataGroupedByTagKey(true)
        break
      case 'VIRTUAL_TAGS_VALUES':
        reduced = getTableDataGroupedByTagValue(true)
        break
      default:
        reduced = []
        break
    }

    return filter(reduced).sort((rowA, rowB) => (rowA.cost > rowB.cost ? -1 : 1))
  }

  const inventoryResources = ref([])
  const resources = ref(0)
  const regions = ref(0)
  const totalCost = ref(0)
  const untaggedResources = ref(0)

  const updateSummary = debounce(() => {
    resources.value = inventoryResources.value.length
    const byResourceID = groupBy.value !== 'RESOURCE_ID' ? getInventoryResources('RESOURCE_ID') : inventoryResources.value
    regions.value = Array.from(
      new Set(
        byResourceID
          .map(({ region }) => region)
          .filter((region) => region !== 'Not found' && region !== 'loading')
      )
    ).length
    totalCost.value = inventoryResources.value.reduce((acc, curr) => acc + curr.cost, 0)
    untaggedResources.value = byResourceID.filter(({ tags }) => tags.length === 0).length
  })

  function update() {
    inventoryResources.value = getInventoryResources(groupBy.value)
    updateSummary()
  }

  function clear() {
    inventoryResources.value = []
    resources.value = 0
    regions.value = 0
    totalCost.value = 0
    untaggedResources.value = 0
  }

  function updateResource(resourceID, payload) {
    const index = inventoryResources.value.findIndex((res) => res.resourceID === resourceID)
    if (index < 0) {
      return
    }
    const resource = inventoryResources.value[index]
    const newPayload = appendVirtualTagsOnResource({
      ...resource,
      ...payload,
      region: resource.region === 'loading' ? payload.region : resource.region,
      service: resource.service === 'loading' ? payload.service : resource.service
    })

    if (resource.provider === 'aws') {
      newPayload.resourceName = getResourceName(resourceID, 'aws')
    }

    inventoryResources.value[index] = newPayload
    updateSummary()
  }

  function exportCSV() {
    const lines = []

    if (groupBy.value === 'RESOURCE_ID') {
      lines.push([
        'Provider',
        'Resource name',
        'Resource ID',
        'Service',
        'Region',
        'Account',
        'Cost'
      ])

      inventoryResources.value.forEach((row) => {
        lines.push([
          row.provider,
          row.resourceName || '',
          row.resourceID || '',
          row.service,
          row.region,
          row.account.name,
          row.cost
        ])
      })
    } else if (groupBy.value === 'REGION') {
      lines.push(['Provider', 'Region', '"# of resources"', 'Cost'])

      inventoryResources.value.forEach((row) => {
        lines.push([row.provider, row.region, row.count, row.cost])
      })
    } else if (groupBy.value === 'SERVICE') {
      lines.push(['Provider', 'Service', '"# of resources"', 'Cost'])

      inventoryResources.value.forEach((row) => {
        lines.push([row.provider, row.service, row.count, row.cost])
      })
    }

    const csv = lines.map((line) => line.join(';')).join('\n')
    const url = window.URL.createObjectURL(new Blob([csv]))
    const a = document.createElement('a')
    const fileName = `export-by-${groupBy.value.toLowerCase()}-${moment().format('YYYY-MM-DD-HH-mm-ss')}.csv`
    a.setAttribute('download', fileName)
    a.setAttribute('href', url)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    window.URL.revokeObjectURL(url)
  }

  return { inventoryResources, resources, regions, totalCost, untaggedResources, exportCSV, update, updateResource, clear }
}

export { useInventoryResources }
