import { useDashboardStore } from '@/store/dashboard.js'
import moment from 'moment'

export function appendVirtualTagsOnResource(resource) {
  const dashboardStore = useDashboardStore()
  const startPeriod = moment(dashboardStore.dateRange[0])

  let virtualTags = []
  for (const virtualTag of dashboardStore.virtualTags) {
    const conditions = virtualTag.values

    for (const condition of conditions) {
      const { resources, filters } = condition

      const addTag = () => {
        const alreadyAdded = virtualTags.find((tag) => tag.key === virtualTag.name && tag.value === condition.value)
        if (!alreadyAdded) {
          virtualTags.push({ key: virtualTag.name, value: condition.value })
        }
      }

      if (Array.isArray(resources)) {
        // This is a simple allocation.
        if (resources.includes(resource.resourceID)) {
          // The resource supposedly has the tag. Now try to check for a backfill period.
          const { backfill_period: backfillPeriod } = virtualTag
          // No backfill period.
          if (!backfillPeriod) {
            addTag()
          } else {
            const backfillMoment = moment(backfillPeriod)
            if (startPeriod.isSameOrAfter(backfillMoment)) {
              addTag()
            }
          }
        }
      } else {
        const result = Object.keys(filters).every((field) => {
          const { operator, value } = filters[field]
          if (field === 'cloud_account') {
            const included = value.indexOf(resource.account.id) !== -1
            if (operator === 'is in' && included) {
              return true
            } else if (operator === 'is not in' && !included) {
              return true
            }
          }
          if (field === 'project_id' || field === 'account_id') {
            const included = value.indexOf(resource.subaccount) !== -1
            if (operator === 'is in' && included) {
              return true
            }
          }
          if (field === 'cloud_provider') {
            const included = value.indexOf(resource.provider) !== -1
            if (operator === 'is in' && included) {
              return true
            } else if (operator === 'is not in' && !included) {
              return true
            }
          }
          if (field === 'cloud_region') {
            const included = value.indexOf(resource.region) !== -1
            if (operator === 'is in' && included) {
              return true
            } else if (operator === 'is not in' && !included) {
              return true
            }
          }
          if (field === 'cloud_service') {
            const included = value.indexOf(resource.service) !== -1
            if (operator === 'is in' && included) {
              return true
            } else if (operator === 'is not in' && !included) {
              return true
            }
          }
          if (field === 'resource_name') {
            const resourceID = resource.resourceID.toLowerCase()
            const name = resource.resourceName?.toLowerCase()
            if (Array.isArray(value)) {
              const included = value.indexOf(resourceID) !== -1 || value.indexOf(name) !== -1
              if (operator === 'is in' && included) {
                return true
              } else if (operator === 'is not in' && !included) {
                return true
              }
            } else {
              const val = value.toLowerCase()
              if (operator === 'contains' && (resourceID.includes(val) || name.includes(val))) {
                return true
              } else if (operator === 'does not contain' && (!resourceID.includes(val) || !name.includes(val))) {
                return true
              }
            }
          }

          if (field === 'cost') {
            if (operator === 'is more than' && resource.cost > value[0]) {
              return true
            } else if (operator === 'is less then' && resource.cost < value[0]) {
              return true
            } else if (operator === 'is equal to' && resource.cost === value[0]) {
              return true
            } else if (
              operator === 'is between' &&
              resource.cost > value[0] &&
              resource.cost < value[1]
            ) {
              return true
            }
          }
          if (field.startsWith('tags')) {
            const { key } = filters[field]
            const { tags } = resource
            if (!Array.isArray(tags)) {
              return false
            }
            // A tag key was specified
            if (key) {
              if (operator === 'is') {
                return tags.find((tag) => tag.key === key && tag.value === value)
              } else if (operator === 'is not') {
                return tags.find((tag) => tag.key === key && tag.value !== value)
              } else if (operator === 'contains') {
                return tags.find((tag) => tag.key === key && tag.value.toLowerCase().includes(value.toLowerCase()))
              } else if (operator === 'does not contain') {
                return tags.find((tag) => tag.key === key && !tag.value.toLowerCase().includes(value.toLowerCase()))
              }
            } else {
              // No key specified, focus on key.
              if (operator === 'is') {
                return tags.find((tag) => tag.key === value)
              } else if (operator === 'is not') {
                return tags.find((tag) => tag.key !== value)
              } else if (operator === 'contains') {
                return tags.find((tag) => tag.key.toLowerCase().includes(value.toLowerCase()))
              } else if (operator === 'does not contain') {
                return tags.find((tag) => !tag.key.toLowerCase().includes(value.toLowerCase()))
              }
            }
          }
          return false
        })
        if (result) {
          addTag()
        }
      }
    }
  }
  return {
    ...resource,
    virtualTags
  }
}

export function appendVirtualTags(resources) {
  return resources.map((resource) => {
    return appendVirtualTagsOnResource(resource)
  })
}
